<template>
  <div>
    <div>
      <b-row class="mx-1 mt-1 mb-3">
        <p class="my-0">
          En esta sección puedes elegir los documentos que deseas financiar en el momento que tu negocio lo necesite y obtener tu dinero al instante.
        </p>
      </b-row>
      <b-row
        class="mx-3"
      >
        <b-col
          md="12"
          style="text-align: center"
        >
          <h2>
            Financiamiento
          </h2>
        </b-col>
        <!-- <b-col
          md="12"
          class="mt-3"
          style="text-align: center"
        >
          <h5>
            Seleccione la forma de subir sus comprobantes
          </h5>
        </b-col> -->
      </b-row>
    </div>
    <!-- Button Bill Register -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center my-2">
          <b-button
            variant="primary"
            :to="{name:'Client-register-receipt'}"
            block
          >
            Registrar comprobante
          </b-button>
        </div>
      </b-form-group>
    </div>
    <b-card>
      <div>
        <div
          v-if="receipts.length==0"
          class="d-flex justify-content-center"
        >
          <h3>
            Aún no han registrado solicitudes
          </h3>
        </div>
        <!-- table -->
        <vue-good-table
          v-else
          ref="MyTable"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionText: 'solicitud(es) seleccionada(s)',
            clearSelectionText: 'Deseleccionar toda(s)',
            selectionInfoClass: 's-box' }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          @on-selected-rows-change="selectionChanged"
        >
          <div
            slot="emptystate"
            class="text-center my-5"
          >
            <h3
              v-if="receipts.length>0"
            >
              Su búsqueda no coincide
            </h3>
            <b-overlay
              id="overlay-background"
              :show="show"
              rounded="lg"
              variant="bg-light"
              opacity="1.00"
              no-wrap
              no-fade
              class="mt-5"
            >
              <h3
                v-if="receipts.length==0"
              >
                Aún no han registrado solicitudes
              </h3>
            </b-overlay>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'documentType'">
              {{ parseDoc(props.row.documentType) }}
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Mostrando grupos de
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
          <div
            slot="selected-row-actions"
            class="container-buttons-bills row align-items-center"
          >
            <b-col>
              <b-row
                class="d-flex justify-content-center mx-1 my-2 my-md-0"
              >
                <!-- <b-button
                  class="mx-1 my-btn"
                  variant="outline-primary"
                  @click="simulate"
                >
                  Simular
                </b-button> -->
                <b-button
                  class="mx-1 my-btn"
                  variant="primary"
                  @click="sendReceipts"
                >
                  Solicitar financiamiento
                </b-button>
              </b-row>
            </b-col>
          </div>
        </vue-good-table>
        <b-row
          v-if="loaded && receipts.length > 0"
          class="mt-2 mx-1 flex-column"
        >
          <p class="my-0">
            FEL → Factura Electrónica
          </p>
          <p class="my-0">
            LET → Letra
          </p>
          <p class="mb-1">
            RHE → Recibo por honorario electrónico
          </p>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BPagination, BFormGroup, BFormSelect, BButton, BCard, BOverlay, BRow, BCol, // BButtonGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BPagination,
    BFormGroup,
    BFormSelect,
    // BButtonGroup,
    BButton,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      show: true,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      userRuc: JSON.parse(localStorage.getItem('userData')).ruc,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Mi cliente',
          field: 'payerName',
        },
        {
          label: 'Fecha registro',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
        },
        {
          label: 'Documento',
          field: 'documentType',
        },
        {
          label: 'Número',
          field: 'documentNumber',
        },
        {
          label: 'Importe pago',
          field: 'originalAmount',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Fecha pago',
          field: 'payingDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
        },
        {
          label: 'Plazo',
          field: 'differenceDay',
          type: 'number',
        },
        /* {
          label: 'Acciones',
          field: 'action',
        }, */
      ],
      rows: [],
      payers: [],
      receipts: [],
      selectedRows: [],
      isSend: false,
      loaded: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        current      : 'light-primary',
        approved : 'light-success',
        rejected     : 'light-danger',
        notApproved     : 'light-warning',
        applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getReceiptsToSend()
  },
  methods: {
    getReceiptsToSend() {
      return new Promise((resolve, reject) => {
        useJwt.getMyReceiptsToSend({ email: this.userEmail, ruc: this.userRuc })
          .then(res => {
            const values = res.data.receipts
            console.log(values)
            for (let i = 0; i < values.length; i += 1) {
              values[i].createdAt = this.normalizeDate(values[i].createdAt)
              values[i].differenceDay = Math.floor((new Date(values[i].payingDate) - new Date()) / (1000 * 3600 * 24))
              if (values[i].differenceDay > 3) {
                values[i].dayStatus = 'current'
              } else if (values[i].differenceDay >= 0) {
                values[i].dayStatus = 'warning'
              } else {
                values[i].dayStatus = 'rejected'
              }
              values[i].payerName = values[i].payerData[0].businessName
            }

            this.rows = values
            this.receipts = this.rows
            this.show = false
            this.loaded = true
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dateFormat(value) {
      return value ? value.split('-').reverse().join('/') : '-'
    },
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
    },
    normalizeDate(date) {
      const newDate = new Date(date)
      newDate.setTime(newDate.getTime() + 5 * 60 * 60 * 1000)
      const dateArr = []
      dateArr.push(newDate.getFullYear().toString(), (newDate.getMonth() + 1).toString().padStart(2, '0'), newDate.getDate().toString().padStart(2, '0'))
      return dateArr.join('-')
    },
    getStatus(status) {
      let label = ''
      let finish

      if (status >= 0) {
        finish = '0'
      } else {
        finish = '1'
      }

      switch (finish) {
        case '0':
          label = `${status} día(s)`
          break
        case '1':
          label = 'Vencido'
          break
        default:
          break
      }
      return label
    },
    sendReceipts() {
      const selected = this.selectedRows
      let flag = true
      for (let index = 0; index < selected.length; index += 1) {
        if (selected[index].differenceDay <= 0) {
          selected[index].vgtSelected = false
          flag = false
        }
      }

      if (flag === false) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Solicitudes vencidas',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'No se pueden enviar solicitudes con fecha de pago vencido',
          },
        },
        {
          timeout: 5000,
        })
      } else {
        useJwt.sendMyReceiptsToSend({
          selected,
          email: this.userEmail,
          ruc: this.userRuc,
        })
          .then(({ data }) => {
            if (data.msg === 'ok') {
              this.$router.replace('/cliente/lista-solicitudes-enviadas')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha(n) enviado la(s) solicitud(es) con éxito.',
                    },
                  },
                  {
                    timeout: 5000,
                  })
                })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: data.Msg ? data.Msg : 'Error al enviar la(s) solicitud(es).',
                },
              },
              {
                timeout: 5000,
              })
            }
          })
          .catch(error => {
            if (error.response.data.error.receiptError) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.error.receiptError,
                },
              },
              {
                timeout: 5000,
              })
            }
            console.log('errores', error)
          })
      }
    },
    simulate() {
      localStorage.removeItem('simulator')
      const selected = this.selectedRows

      let flag = true
      for (let index = 0; index < selected.length; index += 1) {
        if (selected[index].differenceDay <= 0) {
          selected[index].vgtSelected = false
          flag = false
        }
      }

      if (flag === false) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Solicitudes vencidas',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'No se pueden simular solicitudes con fecha de pago vencido',
          },
        },
        {
          timeout: 5000,
        })
      } else {
        const porcentage = (1 / 4)
        for (let index = 0; index < selected.length; index += 1) {
          selected[index].amount = (selected[index].originalAmount * (selected[index].documentType.includes('Factura') ? 0.03 : 0.01)).toFixed(2)
          selected[index].negotiableValue = (selected[index].originalAmount - selected[index].amount).toFixed(2)
          selected[index].discountFee = (selected[index].negotiableValue * (1 - (1 / ((1 + porcentage) ** (selected[index].payingTerm / 360))))).toFixed(2)
          selected[index].valuedAmount = (selected[index].negotiableValue - selected[index].discountFee).toFixed(2)
          selected[index].vgtSelected = false
        }
        localStorage.setItem('simulator', JSON.stringify(selected))
        this.$router.push({ name: 'Bills-simulator' })
      }
    },
    selectionChanged(params) {
      this.selectedRows = []
      this.selectedRows = params.selectedRows
    },
    deleteReceipt(id) {
      this.$swal({
        title: '¿Estás seguro de querer eliminar el comprobante?',
        text: 'No podrás revertir esto',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteReceipt({
            id,
          })
            .then(() => {
              // this.$router.go(0)
              this.getReceiptsToSend()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Éxito',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Se ha eliminado con éxito la solicitud',
                },
              },
              {
                timeout: 8000,
              })
            })
            .catch(error => {
              console.log('errores', error)
            })
        }
      })
    },
    parseDoc(document) {
      switch (document) {
        case 'Factura electronica':
          return 'FEL'
        case 'Recibo por Honorarios Electrónico':
          return 'RHE'
        default:
          return 'LET'
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}

</style>
